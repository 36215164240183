// Toast (+ React Notifications)
.toast {
  &.toast--success {
    background-color: $primary-200;
    border-color: $primary;
  }

  &.toast--error {
    background-color: $danger-100;
    border-color: $danger;
  }
}

// React Notifications (Partial copied from react-notifications css)
.notification {
  @extend .toast;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;

  &.notification-success {
    @extend .toast--success;

    .notification-message .message:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill=%27%23afca0a%27 viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z'/%3E%3C/svg%3E");
    }
  }

  &.notification-error {
    @extend .toast--error;

    .notification-message .message:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill=%27%23eb3342%27 viewBox='0 0 384 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z'/%3E%3C/svg%3E");
    }
  }

  &.notification-enter,
  &.notification-enter-done,
  &.notification-exit,
  &.notification-exit-done {
    @extend .show;
  }

  .notification-message {
    @extend .toast-body;
    padding-right: 3rem;

    .message:after {
      background-repeat: no-repeat;
      content: "";
      display: block;
      height: 1rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
    }
  }
}

.notification-container {
  box-sizing: border-box;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 15px;
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  z-index: 999999;
}

.notification-enter {
  transform: translate3d(100%, 0, 0);
  visibility: hidden;

  &.notification-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
    visibility: visible;
  }
}

.notification-exit {
  transform: translate3d(0, 0, 0);
  visibility: visible;

  &.notification-exit-active {
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
    visibility: hidden;
  }
}
