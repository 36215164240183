// Some selects may not be used in every project

// Form control no border
.form-control--no-border {
  border-color: transparent;
}

// Checkbox / radio
.form-check-label {
  font-size: $small-font-size-rem;
}

.form-check-input {
  height: 1rem;
  width: 1rem;

  &[type="radio"]:checked {
    background-color: $form-check-input-bg;
    background-position: 1px 1px;
    background-size: 12px;
  }
}

// Placeholder
.form-control::placeholder {
  color: $input-color;
  opacity: 1; /* Firefox */
}

// Input icon
.form-control--icon {
  background-repeat: no-repeat;
  background-size: 14px;
}

.form-control--icon--end {
  @extend .form-control--icon;
  background-position: right calc(0.5em + 0.21875rem) center;
  padding-right: calc(2em + 0.875rem);
}

.form-control--icon--start {
  @extend .form-control--icon;
  background-position: left calc(0.5em + 0.21875rem) center;
  padding-left: calc(2em + 0.875rem);
}

.form-control--icon--magnifying-glass {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23afca0a' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z'/%3E%3C/svg%3E");
}

.form-control--icon--calendar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23afca0a' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M128 0V16 64H320V16 0h32V16 64h64 32V96v64 32V480v32H416 32 0V480 192 160 96 64H32 96V16 0h32zM416 192H32V480H416V192zm0-96H32v64H416V96z'/%3E%3C/svg%3E");
}

.form-control--icon--clock {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23afca0a' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112V256v8.6l7.1 4.8 96 64 13.3 8.9 17.8-26.6-13.3-8.9L272 247.4V112 96H240v16z'/%3E%3C/svg%3E");
}

.form-control--icon--chevron-down {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23afca0a' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z'/%3E%3C/svg%3E");
}

.form-control--icon--xmark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23afca0a' viewBox='0 0 384 512'%3E%3C!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z'/%3E%3C/svg%3E");
}

// Form group
.input-group-text {
  color: $primary;
}

button.input-group-text:hover {
  color: $primary-600;
}

// Switch
.form-switch {
  align-items: center;
  display: flex;

  .form-check-input {
    --hf-form-check-bg: #{$gray-300};
    border: 0;
    height: 30px;
  }

  .form-check-label {
    margin-left: 0.5rem;
  }
}

// File custom
.input-file--item {
  border: 1px $input-border-color solid;
  border-radius: $input-border-radius;
  height: 6.25rem;
  overflow: hidden;
  position: relative;
  width: 6.25rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &.input-file--item--sm {
    height: 3.125rem;
    width: 3.125rem;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .input-file--item--remove {
    --fa-primary-color: #{$white};
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$primary};
    --fa-secondary-opacity: 1;
    cursor: pointer;
    position: absolute;
    right: 0.125rem;
    top: 0.125rem;
    transition: $btn-transition;

    &:hover {
      color: $primary-600;
    }
  }
}

.input-file--item--add {
  align-items: center;
  color: $primary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: $btn-transition;

  &:hover {
    color: $primary-600;
  }

  input {
    display: none;
  }
}

// Single select & React select
.select--react-select,
.single-select {
  color: $form-select-color;
  font-size: $input-font-size;

  .select--react-select__control,
  .single-select__control {
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    box-shadow: unset;

    &:hover {
      border-color: $input-border-color;
    }
  }

  .select--react-select__control--is-focused,
  .single-select__control--is-focused {
    border-color: $input-focus-border-color;

    &:hover {
      border-color: $input-focus-border-color;
    }
  }

  .select--react-select__clear-indicator,
  .single-select__clear-indicator {
    padding: 0;

    svg {
      visibility: hidden;
    }
  }

  .select--react-select__dropdown-indicator,
  .single-select__dropdown-indicator {
    padding-left: 0;
  }

  .select--react-select__control--menu-is-open .select--react-select__dropdown-indicator,
  .single-select__control--menu-is-open .single-select__dropdown-indicator {
    transform: rotateZ(180deg);
  }

  .select--react-select__indicator-separator,
  .select--react-select__dropdown-indicator,
  .single-select__indicator-separator,
  .single-select__dropdown-indicator {
    display: none;
  }

  .select--react-select__menu,
  .single-select__menu {
    margin-top: 0;
  }
}

// Single select clear indicator
.single-select__clear-indicator {
  border: 1px solid $primary;
  border-radius: 1rem;
  color: $primary;
  height: 0.75rem;
  position: relative;
  width: 0.75rem;

  &::before,
  &::after {
    background-color: $primary;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 60%;
  }

  &::before {
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  }

  &::after {
    transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
  }
}

// Custom select
.select-custom {
  background-color: $form-select-bg;
  border-color: $form-select-border-color;
  border-radius: $form-select-border-radius;
  border-style: solid;
  border-width: $form-select-border-width;
  font-size: $form-select-font-size;
  width: 100%;

  &.disabled {
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
    color: $form-select-disabled-color;
    pointer-events: none;
  }

  &.is-invalid {
    border-color: $form-invalid-border-color;
  }
}

.select-custom__toggle {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: $form-select-line-height;
  padding-bottom: $form-select-padding-y;
  padding-left: $form-select-padding-x;
  padding-right: $form-select-padding-x;
  padding-top: $form-select-padding-y;

  .select-custom--is-open & {
    border-bottom: 1px solid $form-select-border-color;
  }
}

.select-custom__toggle-value {
  overflow: hidden;
  padding-right: $form-select-padding-x;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.select-custom__toggle-indicator {
  color: $form-select-indicator-color;
}

.select-custom__options {
  display: none;
  max-height: 50vh;
  overflow-y: auto;

  .select-custom--is-open & {
    display: block;
  }
}

.select-custom__option {
  padding: $form-select-padding-y $form-select-padding-x;

  &:hover:not(.selected) {
    background-color: $gray-100;
  }

  &.selected {
    background-color: $gray-300;
  }
}

.single-select__option--is-focused {
  background-color: $gray-100 !important;
}

.single-select__option--is-selected {
  background-color: $gray-200 !important;
  color: $black !important;
}

// Multi select
.multi-select.rmsc {
  --rmsc-main: #{$input-focus-border-color};
  --rmsc-border: #{$input-border-color};
  --rmsc-gray: #{$input-color};

  font-size: $input-font-size;

  .dropdown-container {
    &:focus-within {
      box-shadow: none;
    }

    &[aria-disabled="true"] {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
      color: $input-disabled-color;
    }
  }

  .dropdown-heading-dropdown-arrow {
    display: none;
  }

  .dropdown-content {
    padding-top: 0;

    .item-renderer {
      display: unset;

      input[type="checkbox"] {
        @extend .form-check-input;

        + span {
          @extend .form-check-label;
        }
      }
    }
  }

  .dropdown-heading > .clear-selected-button {
    @extend .form-control--icon, .form-control--icon--xmark;
    background-position: center;
    height: 1rem;
    width: 1rem;

    svg,
    div {
      opacity: 0;
    }
  }
}

// Multi select chevron down & single select chevron down
.multi-select.rmsc .dropdown-heading,
.select--react-select .select--react-select__control,
.single-select .single-select__control {
  @extend .form-control--icon--end, .form-control--icon--chevron-down;
  padding-right: calc(2em + 0.375rem);
}
