// Main
main {
  background-image: url("../assets/img/wave@2x.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50%;
  position: relative;
  width: calc(100% - #{$aside-width});
  z-index: 1;
}

.content {
  overflow-x: auto;
}

// Content
.block-content {
  min-height: $content-block-min-height;
}

.block-content--border {
  border-bottom: 1px solid $tertiary-200;
}

.block-content--px {
  padding-left: 2rem;
  padding-right: 2rem;
}

.block-content--py {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
