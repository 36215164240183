// header
.header {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: $header-height;
  padding-left: 2rem;
  padding-right: 2rem;
  position: sticky;
  top: 0;
  z-index: 200;

  .logo {
    height: 40px;
  }

  .nav-item:last-child > .dropdown > .btn {
    padding-right: 0;
  }

  .dropdown-menu {
    max-height: 500px;
    max-width: 338px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// header actions
.header-actions {
  background-color: $tertiary-100;
  position: sticky;
  top: $header-height;
  z-index: 100;

  .header-actions__content {
    @extend .block-content, .block-content--px, .block-content--border;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;

    h1 {
      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    h4 {
      margin-bottom: 0.10125rem; // set height of .header-actions__content to a total of 100px
    }
  }
}

.header-actions__buttons {
  display: flex;

  > .btn {
    margin-left: 1rem;
  }
}

// subheader
.header-sub {
  .header-sub__content {
    @extend .block-content--px, .block-content--border;
    align-items: center;
    display: flex;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    .nav-item:not(:first-child) {
      margin-left: 1rem;
    }

    .nav-link:not(.active):hover {
      color: $primary;
    }
  }
}

// Dropdown Customer
.dropdown-menu--customer {
  li:not(:last-child) {
    border-bottom: 1px solid $dropdown-border-color;
  }

  .dropdown-item {
    border-left: 5px solid transparent;
    padding-left: calc(var(--hf-dropdown-item-padding-x) - 5px);

    &.selected {
      border-left-color: $primary;
    }
  }

  .dropdown-menu--customer__sticky-item {
    background-color: $white;
    padding: 1rem;
    position: sticky;
    top: 0;
  }
}

// Dropdown Account
.dropdown-menu--account {
  width: 384px;

  li:not(:last-child) {
    border-bottom: 1px solid $dropdown-border-color;
  }

  h5 {
    font-size: $small-font-size-rem;
  }

  .language-option {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 25px;
    margin-right: -15px;

    img {
      margin-right: 0.625rem;
      opacity: 0.4;
    }

    h4 {
      font-size: $small-font-size-rem;
    }

    &.selected {
      img {
        opacity: 1;
      }

      h4 {
        font-weight: $font-weight-bold;
      }
    }
  }
}
