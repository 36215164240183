.page-item.disabled {
  opacity: 0.25;
}

.page-item--desc {
  font-size: $sub-sup-font-size;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.4em;
}
