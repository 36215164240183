.select-time-period {
  padding-left: 4px;
  padding-right: 4px;

  .col-6 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .react-datepicker-wrapper {
    font-size: $input-font-size;
    width: 100% !important;
    z-index: 99;

    input[type="text"]:not([id^="react-select"]) {
      line-height: $input-line-height;
      width: 100%;
    }

    input {
      @extend .form-control, .form-control--icon, .form-control--icon--end, .form-control--icon--clock;
    }
  }

  @media (max-width: 767.98px) {
    .react-datepicker__tab-loop {
      width: calc(50% - 29px) !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .react-datepicker__tab-loop {
      width: 21% !important;
    }
  }

  @media (min-width: 992px) {
    .react-datepicker__tab-loop {
      width: 22.3% !important;
    }
  }

  .react-datepicker__tab-loop {
    position: absolute;
    width: 22%;
    z-index: 100;

    .react-datepicker-popper {
      padding-bottom: 0px;
      padding-top: 6px;
      width: 100% !important;

      .react-datepicker--time-only {
        align-content: center;
        border-color: $gray-300;
        border-radius: 0;
        padding: 0;
        width: 100% !important;

        .react-datepicker__time-container {
          width: 100% !important;

          .react-datepicker__header--time {
            display: none;
          }

          .react-datepicker__time-box {
            width: 100% !important;

            ul.react-datepicker__time-list {
              align-content: center;
              display: flex;
              flex-direction: column;
              margin: 0;
              padding-left: 0;
              width: 100% !important;

              li.react-datepicker__time-list-item {
                font-size: $input-font-size;
                height: unset;
                order: 2;
                padding: calc($input-padding-y * 0.5) $input-padding-x;
                text-align: left;
                width: 100% !important;

                &[data-time-order="smaller"] {
                  order: 3;
                }

                &:after {
                  content: attr(data-time-diff);
                }
              }

              li.react-datepicker__time-list-item--selected {
                background-color: $white;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
