.aside {
  --fa-fw-width: 1.5rem;
  background-color: $tertiary-900;
  bottom: 0;
  flex-shrink: 0;
  height: calc(100vh - $header-height);
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2.5rem 0.5rem;
  position: sticky;
  scrollbar-color: $tertiary $tertiary-900;
  scrollbar-width: thin;
  top: $header-height;
  width: $aside-width;

  &:hover {
    overflow-y: auto;
  }

  .nav-link {
    --hf-nav-link-color: #{$white};
    --hf-nav-link-padding-y: 0.25rem;
    --hf-nav-link-font-size: 0.875rem;

    svg {
      color: $tertiary;
      margin-right: 0.5rem;
    }

    &.active {
      --hf-nav-link-font-weight: #{$font-weight-extrabold};

      svg {
        color: $primary;
      }
    }

    &:hover svg {
      color: $primary;
    }
  }

  // main item
  > .nav > .nav-item {
    margin-bottom: 1.75rem;
    --hf-nav-link-font-size: 0.875rem;

    > .nav-link {
      --hf-nav-link-padding-x: 1rem;
      --hf-nav-link-padding-y: 0.375rem;
      --hf-nav-pills-link-active-bg: rgba(255, 255, 255, 0.2);
      --hf-nav-link-font-weight: #{$font-weight-semibold};
      margin-bottom: 0.25rem;
      position: relative;

      &:hover {
        --hf-nav-link-hover-color: #{$white};
      }

      &.active {
        --hf-nav-link-font-weight: #{$font-weight-extrabold};
      }
    }
  }

  .nav--sub {
    --hf-nav-pills-link-active-color: #{$primary};

    .nav-item {
      margin-bottom: 0.25rem;
    }

    .nav-link {
      --hf-nav-pills-link-active-bg: transparent;
      --hf-nav-link-font-weight: #{$font-weight-normal};
      font-size: 0.75rem;
      padding-left: 3rem;

      &:hover {
        --hf-nav-link-hover-color: #{$primary};
      }

      &.active {
        --hf-nav-link-font-weight: #{$font-weight-bold};
      }
    }
  }
}
