/* Custom */

// Typography
$medium-font-size: 0.875rem;
$small-font-size-rem: 0.75rem;
$extra-small-font-size: 0.625em;
$extra-small-font-size-rem: 0.625rem;

$infographic-font-size: 1.3125rem; // 21px

$font-weight-extrabold: 800;

// Table
$table-borderless-cell-padding-y: 0.625rem;

// Modal
$modal-body-height-xs: 8rem;
$modal-body-height-sm: 16rem;
$modal-body-height-md: 24rem;
$modal-body-height-lg: 32rem;
$modal-body-height-xl: 40rem;

/* Bootstrap */

// Color system

// scss-docs-start gray-color-variables
$white: #ffffff;
$gray-100: #f6f6f6;
$gray-300: #dbdbdb;
$gray-600: #8a96a0;
$black: #000000;

// scss-docs-start color-variables
$green: #289548;

// scss-docs-start theme-color-variables
$primary-100: #f7f9e6;
$primary-200: #d8f3bf; // toaster success bg
$primary: #afca0a;
$primary-500: #63d100; // toaster success border
$primary-600: #8da308; // button hover
$primary-800: $green;

$secondary: #ff870c;
$secondary-600: #e27200; // button hover

$tertiary-100: #f3faff;
$tertiary-200: #e1edf5;
$tertiary: #698796;
$tertiary-900: #004752;

$danger-100: #fde9eb;
$danger: #eb3342;

// Options
$enable-validation-icons: false;

// Prefix for :root CSS variables
$prefix: hf- !default;

// Typography
$font-family-sans-serif: "Gilroy", Arial, sans-serif;
$font-size-base: 1rem;

$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bolder: 900;

$h1-font-size: $font-size-base * 1.75; // 28px
$h2-font-size: $font-size-base * 1.125; // 18px
$h3-font-size: $font-size-base * 0.875; // 14px
$h6-font-size: $font-size-base * 0.875; // 14px
$h5-font-size: $font-size-base * 0.875; // 14px
$h4-font-size: $font-size-base * 0.875; // 14px

// scss-docs-start headings-variables
$headings-font-weight: $font-weight-medium;
$headings-line-height: 1.2;

$small-font-size: 0.75em; // 16px > 12px | 14px > 10.5px
$sub-sup-font-size: 0.625rem; // 10px

// Body
$body-bg: $tertiary-100;

// Links
$link-hover-decoration: none;

// Components
// scss-docs-start border-variables
$border-color: $tertiary-200;
$border-color-translucent: rgba($tertiary-200, 0.5);

// scss-docs-start border-radius-variables
$border-radius: 0.25rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: 0.5rem !default;

// scss-docs-start box-shadow-variables
$box-shadow: 0px 0px 6px 6px rgba($black, 0.1); // card + toast
$box-shadow-sm: 0px 0px 4px 0px rgba($black, 0.25); // tooltip
$box-shadow-lg: 0px 8px 16px 0px rgba($black, 0.15); // modal

// scss-docs-start focus-ring-variables
$focus-ring-opacity: 0;

// scss-docs-start theme-color-variables
$warning: #eb3342;

// Links
$link-color: $primary-800;

// Tables
$table-cell-padding-y: 1rem;
$table-bg: $white;
$table-border-color: $tertiary-200;

// Buttons + Forms
// scss-docs-start input-btn-variables
$input-btn-padding-x: 0.9375rem;
$input-btn-padding-y: 0.4375rem;

// scss-docs-start btn-variables
$btn-padding-y: 0.625rem;
$btn-font-size: 0.75rem;
$btn-font-size-sm: 0.625rem;
$btn-font-weight: $font-weight-semibold;
$btn-disabled-opacity: 0.5;
$btn-link-disabled-color: $tertiary;
$btn-border-radius: 1.25rem;

$btn-link-color: $primary;
$btn-link-hover-color: $primary-600;

// Forms
$grid-gutter-width: 1rem;

// scss-docs-start form-input-variables
$input-padding-x: 0.6875rem;
$input-font-size: 0.75rem;
$input-line-height: 2;
$input-bg: $white;

$input-disabled-color: $gray-600;
$input-disabled-bg: $gray-100;
$input-disabled-border-color: $gray-100;

$input-color: $black;
$input-border-color: $gray-300;

$input-focus-border-color: $green;

// scss-docs-start form-check-variables
$form-check-input-border: var(--#{$prefix}border-width) solid $tertiary;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 11C2.46812 11 0 8.53187 0 5.5C0 2.46812 2.46812 0 5.5 0C8.53187 0 11 2.46812 11 5.5C11 8.53187 8.53187 11 5.5 11Z' fill='%23AFCA0A'/%3E%3C/svg%3E%0A");

// scss-docs-start form-label-variables
$form-label-font-size: $medium-font-size;
$form-label-font-weight: $font-weight-bolder;
$form-label-color: $tertiary-900;

// scss-docs-start form-switch-variables
$form-switch-color: $white;
$form-switch-focus-color: $white;
$form-switch-checked-color: $white;
$form-switch-width: 3.75em;

// scss-docs-start input-group-variables
$input-group-addon-bg: transparent;
$input-group-addon-border-color: transparent;

// scss-docs-start form-select-variables
$form-select-indicator-color: $primary;
$form-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-select-indicator-color}' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z'/%3E%3C/svg%3E");

// Navs
$nav-link-color: $black;

// Dropdowns
$dropdown-bg: $white;
$dropdown-border-width: 0;
$dropdown-border-radius: 0.375rem;
$dropdown-divider-bg: $black;
$dropdown-font-size: $small-font-size;

// Cards
$card-bg: $white;

// Pagination
$pagination-padding-x: 0;
$pagination-padding-y: 0;
$pagination-color: $primary;
$pagination-bg: transparent;
$pagination-border-width: 0px;
$pagination-focus-color: $btn-link-hover-color;
$pagination-focus-bg: transparent;
$pagination-hover-color: $btn-link-hover-color;
$pagination-hover-bg: transparent;
$pagination-disabled-color: $primary;
$pagination-disabled-bg: transparent;

// Accordion
$accordion-padding-y: 0.75rem;
$accordion-padding-x: 0.75rem;
$accordion-bg: $white;
$accordion-border-color: $gray-300;

$accordion-button-color: $tertiary-900;
$accordion-button-bg: transparent;
$accordion-button-active-color: $accordion-button-color;
$accordion-button-active-bg: transparent;

$accordion-icon-color: $primary;
$accordion-icon-active-color: $accordion-icon-color;

// Toasts
// scss-docs-start toast-variables
$toast-font-size: $small-font-size-rem;
$toast-padding-x: 1.5rem;
$toast-border-radius: 0;

// Badges
// scss-docs-start badge-variables
$badge-font-size: 0.5rem;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.375rem;
$badge-padding-x: 0.5rem;
$badge-border-radius: 1.5rem;

// Modals
$btn-close-color: $black !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='#{$btn-close-color}'><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d='M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z'/></svg>");
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.5;
$btn-close-focus-opacity: $btn-close-hover-opacity;
$modal-footer-margin-between: 0rem;

// scss-docs-start modal-variables
$modal-inner-padding: 1.5rem;
$modal-title-line-height: $headings-line-height;
$modal-content-bg: $white;
$modal-content-border-width: 0;
$modal-header-border-width: 1px;
$modal-lg: 754px;

// Code
$code-color: $secondary;

/* Custom #2 */

// Box shadow
$box-shadow-xs: 0px 0px 4px 4px rgba($black, 0.05); // dropdown
