// Padding
.p-3-5 {
  padding: 1.25rem;
}

.pb-3-5 {
  padding-bottom: 1.25rem;
}

// Margin
.ms-4-5 {
  margin-left: 2rem;
}

// Text
.text-green {
  --hf-text-opacity: 1;
  color: rgba($green, var(--hf-text-opacity)) !important;
}

.text-tertiary {
  --hf-text-opacity: 1;
  color: rgba($tertiary, var(--hf-text-opacity)) !important;
}

.text-tertiary-900 {
  --hf-text-opacity: 1;
  color: rgba($tertiary-900, var(--hf-text-opacity)) !important;
}

.text-gray-300 {
  --hf-text-opacity: 1;
  color: rgba($gray-300, var(--hf-text-opacity)) !important;
}

// Background
.bg-green {
  background-color: $green;
}

.bg-gray-300 {
  background-color: $gray-300;
}

// in case of small within 0.875rem small won't fulfill the requirement
.small-fixed {
  font-size: $small-font-size-rem;
}

h2.text-infographic {
  font-size: $infographic-font-size;
  font-weight: $font-weight-bold;
}
// -

// List
ol,
ul {
  padding-left: 1rem;

  li::marker {
    color: $primary-800;
    font-size: 1.125em;
  }
}

// Alert
.alert h4 {
  margin-bottom: 0;
}

// Badge
.badge.badge-sm {
  font-size: $extra-small-font-size;
  line-height: 1.25;
  padding-bottom: 0.375em;
  padding-top: 0.375em;
}

// Divider
hr {
  border-color: $gray-300;
}

// Cursor
.cursor--pointer {
  cursor: pointer;
}

.cursor--default {
  cursor: default;
}

// Unread messages
.messages-unread {
  @extend .badge, .rounded-pill;
  background-color: $danger;
  font-size: $extra-small-font-size-rem;
  line-height: 1;
  min-height: 1rem;
  min-width: 1rem;
  padding: 0.1875rem 0.25rem;
}

// Fontawesome
.svg-inline--fa.fa--1rem {
  font-size: 1rem;

  // .fa-lg styles
  line-height: 0.05em;
  vertical-align: -0.2em;
  width: 1rem;
}
