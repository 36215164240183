.btn-link {
  > svg {
    color: $primary;
    transition: $btn-transition;
  }

  > .btn--rounded.btn-sm {
    > svg {
      color: var(--hf-btn-color);
    }
  }

  &:hover {
    svg {
      color: $primary-600;
    }

    > .btn--rounded {
      background-color: var(--hf-btn-hover-bg);
      border-color: var(--hf-btn-hover-border-color);
    }
  }
}

// Button rounded
.btn--rounded {
  --btn-rounded-size: 2.5rem;
  --hf-btn-border-radius: var(--btn-rounded-size);
  --hf-btn-padding-x: 0;
  --hf-btn-padding-y: 0;
  height: var(--btn-rounded-size);
  line-height: 0;
  width: var(--btn-rounded-size);

  &.disabled,
  &:disabled {
    --hf-btn-disabled-border-color: #{$tertiary-200};
    --hf-btn-disabled-bg: #{$white};
    --hf-btn-disabled-color: #{$tertiary};
    --hf-btn-disabled-opacity: 1;
  }

  &:not(.btn-sm) {
    svg {
      font-size: 1.375em;
    }
  }

  &.btn-sm {
    --btn-rounded-size: 1.75rem;

    > svg {
      font-size: 0.75rem;
    }
  }

  &.btn--rounded-inactive {
    --hf-btn-color: #{$input-color};
    --hf-btn-border-color: #{$input-border-color};
  }
}

span.btn--rounded {
  line-height: 1.75rem;
}

// Button image label
.btn--image-label {
  border-width: 0;
  font-size: $small-font-size;
  justify-content: center;
  line-height: 1;
  margin-left: 7px;
  padding: 0;
  text-align: center;
  width: 84px;
  word-break: break-all;

  &.active {
    font-weight: 900;
  }

  img {
    height: 50px;
    margin-bottom: 0.5rem;
    pointer-events: none;
    user-select: none;
    width: 50px;
  }
}

.row--btn-image-label {
  margin-left: -24px;
  margin-right: -16px;
}

// Button elements within
.btn--element-start,
.btn--element-end {
  align-items: center;
  display: flex;
  justify-content: center;

  &.btn-link {
    > svg {
      font-size: 1.75rem;
      top: 0.375rem;
    }

    &.btn-sm > svg {
      font-size: 1.25rem;
    }
  }
}

.btn--element-start {
  > svg,
  > span {
    &:first-child {
      margin-right: 0.5rem;
    }
  }

  &.btn-sm {
    svg,
    span {
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
}

.btn--element-end {
  > svg,
  > span {
    &:last-child {
      margin-left: 0.5rem;
    }
  }

  &.btn-sm {
    svg,
    span {
      &:last-child {
        margin-left: 0.25rem;
      }
    }
  }
}
