/* App */
// Typography
h1,
.h1 {
  font-weight: $font-weight-normal;
}

h2,
.h2 {
  font-weight: $font-weight-semibold;
}

h3,
.h3 {
  font-weight: $font-weight-bolder;
}

p,
ul,
ol {
  font-size: $medium-font-size;
}

.fw-bold,
b,
strong {
  font-weight: $font-weight-semibold !important;
}

// btn
.btn {
  &:active,
  .active {
    --hf-btn-active-border-color: transparent;
  }
}

.btn-primary,
.btn-secondary {
  --hf-btn-color: #{$white};

  &.disabled,
  &:disabled {
    --hf-btn-disabled-color: #{$white};
  }
}

.btn-primary,
.btn-secondary,
.btn-outline-primary,
.btn-outline-secondary {
  --hf-btn-hover-color: #{$white};

  &:active,
  .active {
    --hf-btn-active-color: #{$white};
  }
}

.btn-primary {
  --hf-btn-hover-bg: #{$primary-600};
  --hf-btn-hover-border-color: #{$primary-600};
}

.btn-outline-primary:hover {
  --hf-btn-hover-bg: #{$primary};
}

.btn-secondary {
  --hf-btn-hover-bg: #{$secondary-600};
  --hf-btn-hover-border-color: #{$secondary-600};
}

.btn-outline-secondary:hover {
  --hf-btn-hover-bg: #{$secondary};
}

.btn-link {
  --hf-btn-font-weight: #{$font-weight-semibold};
  --hf-btn-color: #{$tertiary-900};
  --hf-btn-hover-color: #{$tertiary-900};
  text-decoration: none;

  &:hover {
    svg {
      color: $primary-600;
    }
  }
}

// Form group
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ),
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-radius: var(--hf-border-radius);
}

// Modal
.modal-footer > * {
  margin: 0;
}

// Dropdown
.dropdown-menu {
  box-shadow: $box-shadow-xs;

  .dropdown-item {
    font-size: $small-font-size-rem;
  }
}

// Badge
.badge {
  line-height: 1.5;
}
