.logbook__header {
  box-shadow: 0px 0px 10px rgba($black, 0.1);
}

.logbook {
  --hf-logbook-bg-color: #{$white};
  background-color: var(--hf-logbook-bg-color);
  display: flex;
  height: calc(100vh - #{$header-height * 2}); // 100 vh minus header and subheader

  // Aside
  .logbook__aside {
    border-right: 1px solid $tertiary-200;
    height: 100%;
    max-width: 26rem;
    overflow-y: auto;
    width: 100%;
    z-index: 100;
  }

  .logbook__aside__item {
    border-bottom: 1px solid $gray-300;
  }

  .logbook__aside__item--sticky {
    background-color: var(--hf-logbook-bg-color);
    position: sticky;
    top: 0;
  }

  .logbook-search {
    padding: 1.5rem 1rem;
  }

  .logbook-summary {
    cursor: pointer;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      padding: 1.5rem;
    }

    &.active,
    &:hover {
      h3 {
        color: $primary;
      }
    }
  }

  .logbook-summary--top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    h3.active {
      color: $primary;
      margin-bottom: 0;
    }

    small {
      color: $tertiary;
      font-size: $extra-small-font-size;
    }
  }

  .logbook__last-sender {
    font-size: $medium-font-size;
  }
}
