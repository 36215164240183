.filter-menu {
  @extend .block-content--px;
  align-items: center;
  display: flex;
  padding-bottom: 0.25rem;
  padding-top: 1.25rem;

  .form-control,
  .single-select,
  .multi-select {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;

    @include media-breakpoint-up(md) {
      min-width: 240px;
    }
  }
}

.filter-menu__containter > * {
  margin-right: 0.5rem;
}
