// RouteWizard

// Wizard Step 2
.modal--route-wizard {
  .modal-cleaning-object__block {
    width: 50%;
  }

  .modal-cleaning-object__block-container {
    border: 1px solid $accordion-border-color;
    min-height: calc(100% - 32px);
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .modal-cleaning-object__block-arrows {
    align-items: center;
    color: $gray-600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    svg:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  .accordion-header {
    .badge {
      margin-left: 0.25rem;
    }
  }

  .accordion-body {
    h5 {
      font-size: $small-font-size-rem;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  .btn--accordion-dragger {
    padding: 0 0.5rem 0 0;

    svg {
      color: $gray-600;
    }
  }
}

// Wizard Step 3
.wizard__route-activities-step {
  .accordion-button::after {
    right: 0.75rem;
  }
}
